import {useMemo} from 'react';

/**
 * Used specifically to get a client only representation of the current URL when we know a cached response is out of
 * sync with what's in the browser. eg: ?utm_medium, etc...
 */
export function useBrowserUrl() {
  if (typeof document === 'undefined') return undefined;
  /**
   * We return a `URL` object instead of passing through `location` because
   * the URL object contains important info like hostname, etc.
   */
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(() => new URL(window.location.href), []);
}
